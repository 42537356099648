import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import tw from "twin.macro"

const Wrap = tw(motion.div)`inline-flex w-auto`

const Chevron = ({ state = "down", fill = "black", size }) => {
  const transition = {
    duration: 0.8,
    ease: [0.04, 0.62, 0.23, 0.98],
  }
  const variants = {
    down: {
      rotate: 0,
      transition: transition,
    },
    up: {
      rotate: 180,
      transition: transition,
    },
    left: {
      rotate: 90,
      transition: transition,
    },
    right: {
      rotate: -90,
      transition: transition,
    },
  }
  return (
    <AnimatePresence>
      <Wrap
        style={{
          scale: size === "big" ? 3.5 : 1,
        }}
        animate={state}
        variants={variants}
      >
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.46777 0.280273L9.90723 0.719727L5 5.62695L0.0927734 0.719727L0.532227 0.280273L5 4.74805L9.46777 0.280273Z" fill={fill} />
        </svg>
      </Wrap>
    </AnimatePresence>
  )
}

export default Chevron
