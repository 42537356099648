import React from "react"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import { AnimatePresence, motion } from "framer-motion"
import Chevron from "@elements/Chevron"

const Section = tw.section`relative`
const Wrap = tw.div`max-w-screen-md mx-auto`

const WpAcfAccordionBlock = ({ moduleData }) => {
  const module = moduleData.modulesBlogAccordion
  if (module.items?.length < 1) return null
  return (
    <Section>
      <Wrap>
        {module.items.map((item, index) => {
          return <AccordionItem key={`accordion-${index}`} item={item} index={index} />
        })}
      </Wrap>
    </Section>
  )
}

const AccordionBox = tw(motion.div)`relative overflow-hidden mx-0 border-b border-gray-400 transition-all duration-300`
const AccordionTitleWrap = tw(motion.div)`py-3 sm:py-5 relative flex items-center cursor-pointer w-full justify-between`
const AccordionTitle = tw(motion.p)`relative pr-5`
const AccordionDescription = styled(motion.div)`
  ${tw`relative text-base lg:text-xl`}
  *:first-child {
    ${tw`!mt-0`}
  }
  *:last-child {
    ${tw`!mb-0`}
  }
  a {
    //default link styles
    ${tw`text-blue-700 hover:text-blue-800 visited:text-purple-700 hover:underline`}//underline links
  }
  ul {
    br {
      ${tw`hidden`}
    }
    li {
      ${tw`relative pl-6`}

      &::before {
        content: "";
        ${tw`absolute left-0 w-3 h-3 bg-no-repeat bg-contain top-2`}
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjMiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA2MyA1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYzIDI5TDMzLjk2ODIgNThMMzEuMTkxNSA1NS4yMjY0TDU1LjQ3MjEgMzAuOTcyNEwwIDMwLjk3MjRMMCAyNy4wMjc2TDU1LjQ3MjEgMjcuMDI3NkwzMS4xOTE1IDIuNzczNjVMMzMuOTY4MiAwTDYzIDI5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
      }
    }
  }
`

const AccordionItem = ({ item, index }) => {
  const [expanded, setExpanded] = React.useState(false)
  const isOpen = index === expanded
  return (
    <AccordionBox
      whileHover={{
        borderBottomColor: "#000000",
      }}
    >
      <AccordionTitleWrap initial={false} onClick={() => setExpanded(isOpen ? false : index)}>
        {item.title && <AccordionTitle>{item.title}</AccordionTitle>}
        <Chevron state={isOpen ? "up" : "down"} fill="#666666" />
      </AccordionTitleWrap>
      <AnimatePresence initial={false}>
        {isOpen && item.content && (
          <AccordionDescription initial="init" animate="open" exit="init" variants={accordionVariants}>
            {parse(item.content)}
            <br />
          </AccordionDescription>
        )}
      </AnimatePresence>
    </AccordionBox>
  )
}

const transition = {
  duration: 0.5,
  ease: [0.04, 0.62, 0.23, 0.98],
}
const endTransition = {
  duration: 0.5,
  ease: [0.04, 0.93, 0.95, 0.98],
}
const accordionVariants = {
  open: { opacity: 1, height: "auto", transition: endTransition },
  init: { opacity: 0, height: 0, transition: transition },
}

export default WpAcfAccordionBlock
