import React from "react"
import tw, { styled } from "twin.macro"

const Section = tw.section``
const Wrap = styled.div`
  ${tw`min-h-[237px] xl:min-h-[320px]`}
  max-width: 1254px;

  .headline-list-grid {
    ${tw`text-[32px] !leading-[1.17] xl:text-5xl !font-display text-left text-black mb-5`}
    &-large {
      ${tw`text-[40px] xl:text-[54px] 2xl:text-[70px] 2xl:-mt-1`}
    }
  }
  .text-list-grid {
    ${tw`text-[16px] !leading-[1.42] xl:text-2xl text-left text-black`}
    &-small {
      ${tw`text-[16px] md:text-base xl:text-xl`}
    }
    &-medium {
      ${tw`text-[18px] md:text-xl xl:text-2xl`}
    }
    &-large {
      ${tw`text-xl xl:text-2xl 2xl:text-[28px]`}
    }
  }
`

const WpAcfBlogListGridBlock = ({ moduleData }) => {
  const module = moduleData.modulesBlogListGrid
  if (module.blocks?.length < 1) return null

  return (
    <Section>
      {/* 3 column grid */}
      <Wrap className="grid grid-cols-1 mx-auto gap-x-3 gap-y-5 md:grid-cols-3">
        {module.blocks.map((block, index) => {
          return (
            <div key={`blog-list-grid-${index}`} className="px-7 md:px-5 pb-7 lg:pb-8 pt-8 bg-[#00FF00] w-full h-full flex justify-between flex-col" style={{ backgroundColor: block.color }}>
              {block.headline && <p className={`headline-list-grid headline-list-grid-${block.headlineSize}`}>{block.headline}</p>}
              {block.text && <p className={`text-list-grid text-list-grid-${block.textSize}`}>{block.text}</p>}
            </div>
          )
        })}
      </Wrap>
    </Section>
  )
}

export default WpAcfBlogListGridBlock
